import axios from "axios";

const baseURL = process.env.REACT_APP_API_KEY;

const getClientData = (client) => {
    return axios.post(`${baseURL}/GetClientData`, client);
}

const approveClientProduct = (client) => {
    return axios.post(`${baseURL}/ApproveClientProduct`, client);
}

const getAppointmentData = (appointment) => {
    return axios.post(`${baseURL}/GetAppointmentData`, appointment);
}

const getAppointmentDataForSupplier = (appointment) => {
    return axios.post(`${baseURL}/GetAppointmentDataForSupplier`, appointment);
}

const approveApprointments = (appointment) => {
    return axios.post(`${baseURL}/ApproveApprointments`, appointment);
}

const api = {
    getClientData, approveClientProduct, getAppointmentData, getAppointmentDataForSupplier, approveApprointments
};
export default api;