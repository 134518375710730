import { Button, Container, Image, Link, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export default function Footer() {

    const [showScrollButton, setShowScrollButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 0) {
                setShowScrollButton(true);
            } else {
                setShowScrollButton(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const handleScrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    return (
        <>
            <Container as="footer" variant={"footer"}>
                <VStack alignItems={"flex-start"} gap="30px">
                    <Text
                        variant={"runningText"}
                        color={"white"}
                        display={"flex"}
                        alignItems={"center"}
                        gap="20px">
                        <Image src="/assets/icons/location.webp" w="24px" h="32px" alt="" />
                        מדינת היהודים 85 הרצליה</Text>
                    <Link
                        href="mailto:sherut@byashir.co.il?subject=I saw your website and I'm interested in getting more information."
                        display={"flex"}
                        alignItems={"center"}
                        gap="20px">
                        <Image src="/assets/icons/massege.webp" w="30px" h="20px" alt="" />
                        sherut@byashir.co.il</Link>
                    <Link
                        href="tel:072-224-8888"
                        display={"flex"}
                        alignItems={"center"}
                        gap="20px">
                        <Image src="/assets/icons/contact.webp" w="24px" h="24px" alt="" />
                        072-2248888</Link>
                </VStack>
                <VStack alignItems={"flex-start"} gap="30px">
                    <Text variant={"runningText"} color={"white"}>רפואה פרטית בעלות ציבורית</Text>
                    <Link
                        href="http://www.byashir.co.il/"
                        target="_blank"
                        color={"white"}
                    >
                        לאתר החברה</Link>
                </VStack>
            </Container>

            {showScrollButton && <Button display={{ base: "none", md: "flex" }} variant={"scrollTop"} onClick={handleScrollTop} >
                <Image src="/assets/icons/topArrow.svg" w="35px" h="35px" alt="גלול לראש הדף" />
            </Button>
            }
        </>

    )
}