import { Container, Image, Link, Text } from "@chakra-ui/react";

export default function Header() {
    return (
        <Container as="header" variant={"header"}>
            <Image src="/assets/images/logo.webp" alt="לוגו בריאות ישיר" w={{ base: "140px", md: "auto" }} /> {/* 140px on mobile, auto on larger screens*/}
            <Link href="tel:072-224-8888"
                color={"primary.runningText"}
                border={"1px solid"}
                borderColor={"primary.runningText"}
                borderRadius={"10px"}
                padding={"6px 20px"}
                display={"flex"}
                alignItems={"center"}
                gap={"10px"}
                _hover={{ textDecoration: "none" }}
            >
                <Text as="span" variant="runningTextSmall">072-224-8888</Text>
                <Image src="/assets/icons/phone.svg" alt=""  w={{ base: "20px", md: "24px" }} />
            </Link>
        </Container>
    )
}