import { Table, extendTheme } from "@chakra-ui/react";
import buttonTheme from "./components/button";
import containerTheme from './components/container';
import checkboxTheme from "./components/checkbox";
import textareaTheme from "./components/textarea";
import selectTheme from "./components/select";
import tableTheme from "./components/table";

const theme = extendTheme({
    initialColorMode: "light",
    useSystemColorMode: false,
    breakpoints: {
        // mobile: "0px",
        // tablet: "1280px",
        // laptop: "1281px",
        // desktop: "1440px",
        sm: "320px",  // Small screens / smartphones
        md: "768px",  // Medium screens / tablets
        lg: "1024px", // Large screens / laptops
        xl: "1280px", // Extra large screens / desktops
    },
    fonts: {
        heading: "tahoma, tahoma-w01-regular, tahoma-w02-regular, tahoma-w10-regular, tahoma-w15--regular, tahoma-w99-regular, sans-serif",
        body: "heebo-light, sans-serif",
        mono: "heebo-light, sans-serif",
        text: "heebo-light, sans-serif",
    },
    colors: {
        primary: {
            runningText: "#004489",
            green: "#689598",
            purple: "#a0138e",
            orange: "#ef7d00",
            error: "#F4354B",
            lightGreen: "#81B9BB",
        },
    },
    styles: {
        global: {
            body: {
                position: "relative",
                zIndex: 1,
            },
        },
    },
    components: {
        Heading: {
            baseStyle: {
                color: "primary.runningText",
            },
            variants: {
                h1: {
                    color: "white",
                    fontSize: ["24px", "48px"], // Mobile: 24px, Desktop: 48px
                    fontStyle: "normal",
                    fontWeight: "normal",
                    lineHeight: "normal",
                    borderBottom: "12px solid",
                    borderColor: "primary.orange",
                    textAlign: "center",
                    position: "absolute",
                    top: "70px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    textWrap: "nowrap",
                    paddingBottom: "15px",
                },
                h2: {
                    fontSize: ["18px", "32px"], // Mobile: 18px, Desktop: 32px
                    fontStyle: "normal",
                    fontWeight: "normal",
                    lineHeight: "normal",
                },
                h3: {
                    fontSize: ["16px", "20px"], // Mobile: 16px, Desktop: 22px
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                },
            },
            defaultProps: {
                size: "",
                fontSize: "",
                lineHeight: "",
                variant: "",
                colorScheme: "",
                margin: 0,
                padding: 0,
            },
        },
        Text: {
            baseStyle: {
                color: "primary.runningText",
            },
            variants: {
                runningText: {
                    fontSize: ["16px", "20px"], // Mobile: 16px, Desktop: 22px
                    fontStyle: "normal",
                    fontWeight: 400,
                    lineHeight: "28px",
                },
                runningTextSb: {
                    fontSize: ["16px", "20px"], // Mobile: 16px, Desktop: 22px
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                },
                runningTextSmall: {
                    fontSize: ["14px", "18px"], // Mobile: 14px, Desktop: 18px
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                },
            },
        },
        FormLabel: {
            baseStyle: {
                color: "primary.runningText",
                fontSize: ["14px", "16px"], // Mobile: 16px, Desktop: 22px
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
            },
        },
        Link: {
            baseStyle: {
                color: "white",
                fontSize: ["16px", "20px"], // Mobile: 16px, Desktop: 22px
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
            },
        },
        Button: buttonTheme,
        Container: containerTheme,
        Checkbox: checkboxTheme,
        Textarea: textareaTheme,
        Select: selectTheme,
        Table: tableTheme,
    },
});

export default theme;
