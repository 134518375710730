import { Box } from "@chakra-ui/react";

export default function Success() {
    return(
        <Box
          as="h1"
          bgColor="primary.lightGreen"
          color="white"
          width="100%"
          padding="100px 0px"
          fontSize= "48px"
          fontStyle= "normal"
          fontWeight= "normal"
          lineHeight= "normal"
        >אישור נשלח למוקד בריאות ישיר</Box>
    )
}