import { HStack, Heading, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import api from "../services/api";
import { useLocation } from "react-router-dom";
import { formatDate, formatTime } from "../reusableCode/formatDateAndTime";

export default function AppointmentDetails() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uq = queryParams.get('uq');

    const [appointment, setAppointment] = useState(null);

    useEffect(() => {
        api.getAppointmentData({ uniqId: uq })
            .then(response => {
                setAppointment(response.data);
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
            });
    }, []);

    return (
        <>
            <Heading as="h1" variant="h1">אישור הזמנת שירות</Heading>
            <VStack p="60px 16px">
                {appointment?.cName && <HStack>
                    <Text variant="runningTextSb">שם המנוי\ה: </Text>
                    <Text variant="runningText">{appointment.cName}</Text>
                </HStack>
                }
                {appointment?.cIdNumber && <HStack>
                    <Text variant="runningTextSb">מס ת.ז: </Text>
                    <Text variant="runningText">{appointment.cIdNumber}</Text>
                </HStack>
                }
                {appointment?.cPhone && <HStack>
                    <Text variant="runningTextSb">טלפון הנבדק/ת ו/או איש קשר: </Text>
                    <Text variant="runningText">{appointment.cPhone}</Text>
                </HStack>
                }
                {appointment?.productName && <HStack>
                    <Text variant="runningTextSb">שם כתב השירות: </Text>
                    <Text variant="runningText">{appointment.productName}</Text>
                </HStack>
                }
                {appointment?.treatmentName && <HStack>
                    <Text variant="runningTextSb">עבור: </Text>
                    <Text variant="runningText">{appointment.treatmentName}</Text>
                </HStack>
                }
                {appointment?.supplierName && <HStack>
                    <Text variant="runningTextSb">שם הרופא/ה: </Text>
                    <Text variant="runningText">{appointment.supplierName}</Text>
                </HStack>
                }
                <VStack pt="20px">
                    <Text variant="runningTextSb">תואם ל: </Text>
                    {appointment?.appointmentDataRow?.map(ap => {
                        return <HStack key={ap.appointmentId}>
                            <Text variant="runningText">{`תאריך: ${formatDate(ap.appointemtDate)} בשעה: ${formatTime(ap.appointemtDate)}`}</Text>
                        </HStack>
                    })
                    }
                </VStack>
                <Text variant="runningTextSb" pt="30px">השתתפות עצמית של המנוי/ה שולמה ישירות לבריאות ישיר שירותי רפואה בע''מ התחייבות זו בתוקף עבור בדיקה אחת בלבד</Text>
            </VStack>
        </>
    )
}