import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const baseStyle = definePartsStyle({
  field: {
    // width: "230px",
    boxShadow: " 0px 2px 20px 0px rgba(0, 0, 0, 0.08)",
    background: "#FFF",
    borderWidth: "1px",
    borderRadius: "12px",
    borderColor: "primary.runningText",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    color: "primary.runningText",
    _invalid: {
      borderColor: "primary.error",
    },
  },
  icon: {
  }
});

const brand = definePartsStyle({});

const selectTheme = defineMultiStyleConfig({
  baseStyle,
  defaultProps: { variant: brand },
  variants: { brand },
});

export default selectTheme;
