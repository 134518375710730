import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const header = defineStyle({
    maxW: "100%",
    w: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "fixed",
    top: "0",
    bgColor: "white",
    zIndex: "1",
});

const main = defineStyle({
    position: "relative",
    maxW:"100%",
    // minH: "100vh",
    marginTop: "109px",
    textAlign: "center",
    padding: "0px",
    // "&:before": {
    //     zIndex: -1,
    //     height: "713px",
    //     left: 0,
    //     right: 0,
    //     position: "absolute",
    //     top: 0,
    //     content: "''",
    //     background: 'linear-gradient(rgba(56, 64, 70, 0.3), rgba(56, 64, 70, 0.3)), url("assets/images/medical4youPlazma.jpg")',
    //     backgroundSize: "cover",
    // },
});

const footer = defineStyle({
    maxW: "100%",
    w: "100%",
    minH: "318px",
    padding: "80px 0px",
    bgColor: "primary.green",
    display: "flex",
    justifyContent: "space-around",
    gap: "30px",
    alignItems: "flex-start",
    flexWrap:"wrap",
    // position: "fixed",
    // bottom: "0",
});

const containerTheme = defineStyleConfig({
    variants: { header, main, footer },
});

export default containerTheme;
