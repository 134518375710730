import { Button, Divider, Heading, ListItem, OrderedList, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from '../services/api';

export default function MedicalDisclosure({ client,urlData }) {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = async () => {
        setIsLoading(true);
        try {
            const resp = await api.approveClientProduct(urlData);
            if (resp.data.message.code === 1) {
                navigate("/success");
            }
        } catch (error) {
            console.error("An error occurred in approveClientProduct:", error);
        } finally {
            setIsLoading(false);
        }
    }
    

    return (
        <>
            <Heading as="h1" variant="h1">אישור הזמנת שירות</Heading>
            <VStack p={{ base: "60px 30px", md: "60px 130px" }} gap="30px">
                <Heading as="h2" variant="h2">טופס גילוי Medical-4–U
                    <Divider borderColor={"primary.green"} borderWidth="3px" w="50%" m="10px auto auto auto" />
                </Heading>
                <Text variant="runningText" >שלום {client?.name}</Text>
                <Text variant="runningTextSb">מסמך זה מהווה טופס גילוי בלבד אשר אינו כולל את כל התנאים של כתב השירות המלא. רק כתב השירות המלא כולל את כל תנאי ההתקשרות.</Text>
                <OrderedList textAlign={"left"} spacing={3} color={"primary.runningText"} fontWeight={"bold"}>
                    <ListItem>
                        <Text variant="runningTextSb" as="h3" mb="10px">פרטי הספק</Text>
                        <Text variant="runningText">בריאות ישיר שירותי רפואה בע"מ, ח.פ. 514906197 ("<b>בריאות ישיר</b>");</Text>
                        <Text variant="runningText">כתובת: חיים לבנון 63, תל אביב-יפו, מיקוד 6997804</Text>
                        <Text variant="runningText">פרטי התקשרות: 072-2248888; פקס: 03-5031872; דוא"ל: Sherut@byashir.co.il</Text>
                        <Text variant="runningText">כל השירותים יסופקו באמצעות "הרצליה מדיקל סנטר" ח.פ 513609867, או נותן שירות אחר שמונה על ידי החברה למתן השירותים ("<b>הספק</b>");</Text>
                    </ListItem>
                    <ListItem>
                        <Text variant="runningTextSb" as="h3" mb="10px">השירותים הכלולים במסגרת העסקה</Text>
                        <OrderedList marginInlineStart={"0em"}>
                            <ListItem>
                                <Text as="span" variant="runningText"> טיפולי פיזיותרפיה – בכפוף לכל התנאים המפורטים בכתב השירות. השירות כרוך בתשלום השתתפות עצמית בגובה של 49 ₪ לכל טיפול. דמי ההשתתפות העצמית ישולמו על ידי המנוי ישירות לספק.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> רופא מלווה מחלה – בכפוף לכל התנאים המפורטים בכתב השירות.</Text>
                                <OrderedList>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> מנוי יהיה זכאי לקבל שירות זה באם הינו עומד בקריטריונים המפורטים בכתב השירות ולגבי המחלות הבאות: סרטן (Cancer), מחלות לב, מחלת הסוכרת, יתר לחץ דם.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> תשלום השתתפות עצמית מהווה תנאי לקבלת השירות, ותשולם כך:</Text>
                                        <OrderedList>
                                            <ListItem>
                                                <Text as="span" variant="runningText"> רופא אישי מלווה- 175 ₪ לפגישה, עד שלוש פגישות לתקופה.</Text>
                                            </ListItem>
                                            <ListItem>
                                                <Text as="span" variant="runningText"> רופא כאב- 200 ₪ השתתפות עצמית.</Text>
                                            </ListItem>
                                            <ListItem>
                                                <Text as="span" variant="runningText"> מימוש זכויות- 15% מגובה ההחזר או 120₪ הגבה מבניהם.</Text>
                                            </ListItem>
                                            <ListItem>
                                                <Text as="span" variant="runningText"> מרפא בעיסוק- 49 ₪ לכל ביקור, עד 12 טיפולים לתקופה.</Text>
                                            </ListItem>
                                            <ListItem>
                                                <Text as="span" variant="runningText"> שירותי אחות- ללא השתתפות עצמית.</Text>
                                            </ListItem>
                                            <ListItem>
                                                <Text as="span" variant="runningText"> שירותי עו"ס- ללא השתתפות עצמית.</Text>
                                            </ListItem>
                                        </OrderedList>
                                    </ListItem>
                                </OrderedList>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> שירות ביקור רופא 24 שעות ביממה – בכפוף לכל התנאים המפורטים בכתב השירות.</Text>
                                <OrderedList>
                                    <ListItem>
                                        <Text as="span" variant="runningText">השירות כרוך בתשלום השתתפות עצמית בסך 25₪.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> השירות ניתן בכל מקום ישוב בישראל למעט יהודה, שומרון וחבל עזה, אך לרבות מעלה אדומים, אריאל, בית אריה, אורנית ושערי תקווה. ברמת הגולן שירות ביקור רופא יינתן בקצרין או בכל מקום ישוב אחר באזור, כאשר המנוי הנזקק לשירות הרפואי יצטרך להגיע בכוחות עצמו ועל חשבונו אל רופא השירות לפי כתובת שתימסר לו על ידי מוקד השירות.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> מחויבותו של הספק כלפי המנוי בטיפולו בקריאה של מנוי לקבלת שירות ביקור רופא תסתיים בהתרחש אחד משני האירועים המפורטים להלן:</Text>
                                        <OrderedList>
                                            <ListItem>
                                                <Text as="span" variant="runningText"> הגעת רופא הסכם לבית המנוי או הפניית החולה למוקד הרפואי הסמוך למקום המצאו, בכפוף לאמור בסעיף 2.3.2 לעיל.</Text>
                                            </ListItem>
                                            <ListItem>
                                                <Text as="span" variant="runningText"> ביטול הקריאה לקבלת השירות ע"י הודעת המנוי למוקד השירות</Text>
                                            </ListItem>
                                        </OrderedList>
                                    </ListItem>
                                </OrderedList>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> ייעוץ רפואי כללי טלפוני - בכפוף לכל התנאים המפורטים בכתב השירות.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> פינוי רפואי וטיפול בחירום - בכפוף לכל התנאים המפורטים בכתב השירות.</Text>
                                <OrderedList>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> מנוי יהא זכאי להחזר עלות פינוי חירום רפואי באמבולנס בהימצא אישור מראש של מוקד השירות.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> מקסימום תקרה להחזר - 1,000 ₪, החזר אחד בלבד לכל שנת זכאות.</Text>
                                    </ListItem>
                                </OrderedList>
                            </ListItem>
                            <ListItem>
                                <Text variant="runningText">בדיקות אבחנתיות – בכפוף לכל התנאים המפורטים בכתב השירות.</Text>
                                <OrderedList>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> המנוי זכאי לבדיקות אבחנתיות המפורטות להלן:</Text>
                                        <OrderedList>
                                            <ListItem>
                                                <Text as="span" variant="runningText"> בדיקת M.R.I</Text>
                                            </ListItem>
                                            <ListItem>
                                                <Text as="span" variant="runningText"> בדיקת C.T.</Text>
                                            </ListItem>
                                            <ListItem>
                                                <Text as="span" variant="runningText"> בדיקת .C.T cardiac</Text>
                                            </ListItem>
                                        </OrderedList>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> המנוי ישלם השתתפות עצמית, למכון ההסדר או לספק, כפי שיימסר למנוי על ידי מוקד השירות, בסכום השווה ל – 20% מתעריף הבדיקה על פי מחירון מכון ההסדר ללקוחות פרטיים במועד הבדיקה.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> עלות הבדיקות, מעבר להשתתפות העצמית, תכוסה על ידי הספק; וזאת, עד לתקרה של 3,000₪ (על פי מחירון מכון ההסדר ללקוחות פרטיים במועד הבדיקות בניכוי השתתפות עצמית (כאמור בסעיף2.6.2 לעיל) לשנת זכאות בגין כל מנוי.</Text>
                                    </ListItem>
                                </OrderedList>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> התייעצויות עם רופאים מומחים – בכפוף לכל התנאים המפורטים בכתב השירות.</Text>
                                <OrderedList>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> המנוי ישלם השתתפות עצמית בסך של 150 ₪, לרופא ההסכם או לספק, כפי שיימסר לו על ידי מוקד השירות, בגין כל התייעצות.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> לרופא שאינו רופא הסכם, ישלם הספק למנוי סך של 80% מעלות כל התייעצות כפי ששולמה בפועל על ידי המנוי ולא יותר מ- 500 ₪ להתייעצות, בכפוף להצגת חשבונית המקור.</Text>
                                    </ListItem>
                                </OrderedList>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText">טיפולים אלטרנטיביים ברפואה משלימה - בכפוף לכל התנאים המפורטים בכתב השירות.</Text>
                                <OrderedList>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> המנוי ישלם למכון ההסדר או הספק, לפי החלטת הספק, השתתפות עצמית בסך של 49 ₪ לטיפול.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> השירות אינו כולל הוצאות של המנוי בגין רכישת תרופות, צמחי מרפא וחומרים אחרים שהומלצו ע"י נותן השירות לצורך הטיפול.</Text>
                                    </ListItem>
                                </OrderedList>
                            </ListItem>
                        </OrderedList>
                    </ListItem>
                    <ListItem>
                        <Text variant="runningTextSb" as="h3" mb="10px">מחיר העסקה הכולל</Text>
                        <OrderedList marginInlineStart={"0em"}>
                            <ListItem>
                                <Text as="span" variant="runningText"> סכום דמי המנוי הינו {client?.total} ₪ לחודש.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> סכום דמי המנוי יהיו צמודים למדד יוקר הבריאות כהגדרתו ובהתאם לתנאים הנקובים בכתב השירות.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> בריאות ישיר שומרת לעצמה את הזכות לעדכן אחת לתקופה של 48 חודשים בשיעור של עד 5% בכל פעם, את הסכומים הנקובים בכתב השירות, לרבות את דמי המנוי וסכומי ההשתתפות העצמית, ובלבד שתימסר על כך הודעה למנוי 30 יום לפחות לפני מועד השינוי.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText">מובהר ומוסכם בזאת, כי בריאות ישיר רשאית לבטל זכאותו של המנוי לשירותים על פי כתב השירות בכל עת ומכול סיבה שהיא בכפוף להודעה מוקדמת למנוי בת 30 ימים מראש ובכתב. ככל שיבוטל מתן השירות כאמור על ידי בריאות ישיר, תבצע בריאות ישיר עדכון לתשלום המשולם על ידי המנוי בהתאמה.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> על אף האמור לעיל, במקרה של ביטול ו/או סיום ו/או פקיעת ההסכם שבין בריאות ישיר לבין הספק בקשר עם מתן השירותים נשוא כתב השירות, מכל סיבה שהיא, רשאית בריאות ישיר להפסיק מתן השירותים על פי כתב השירות, לאחר שהודיעה על כך בכתב למנויים ו/או תהיה רשאית בריאות ישיר להמשיך את מתן השירותים כמוגדר בכתב השרות באמצעות ספק אחר וזאת עפ"י שיקול דעתה הבלעדי של בריאות ישיר.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> מובהר, כי במועד פקיעתו, ביטולו או סיום תקפו של כתב השירות, מכל סיבה שהיא, תפקע כל זכות של המנוי לקבלת השירותים על פי כתב השירות, לרבות במקרה שהמנוי פנה למוקד השירות לקבלת השירות לפני ביטולו, פקיעתו או סיום תקפו של כתב השירות, אך טרם קיבל את השירות בפועל, ולרבות במקרה שהמנוי התחיל בקבלת שירות נשוא כתב השירות לפני סיום תקפו אך לא השלימו.</Text>
                            </ListItem>
                        </OrderedList>
                    </ListItem>
                    <ListItem>
                        <Text variant="runningTextSb" as="h3" mb="10px">זכות המנוי לביטול העסקה</Text>
                        <Text variant="runningText">המנוי רשאי לבטל את העסקה תוך 30 ימים מיום החתימה על החוזה או מיום אישור המנוי כי קיבל את טופס הגילוי, לפי המאוחר.</Text>
                    </ListItem>
                    <ListItem>
                        <Text variant="runningTextSb" as="h3" mb="10px">אחריות</Text>
                        <OrderedList marginInlineStart={"0em"}>
                            <ListItem>
                                <Text as="span" variant="runningText"> בריאות ישיר אינה אחראית לטיב השירותים הרפואיים ו/או האחרים הניתנים למנוי במסגרת כתב השירות. בריאות ישיר אינה אחראית לכל נזק, הפסד הוצאה או תוצאה אחרת שיגרמו למנוי ו/או לכל אחד אחר על ידי הספק ו/או מי מטעמו ו/או עקב בחירתו של המנוי ו/או הפנייתו לנותן השירות ו/או עקב מעשה או מחדל של הספק או נותן השירות או כל גורם הפועל מטעמו, לרבות בגין אי מתן השירות במועד שנקבע לכך מכל סיבה שהיא.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> מבלי לגרוע מן האמור לעיל, מובהר כי בריאות ישיר אינה המעסיקה של הספק ו/או של נותני השירות, מרפאות ומכונים שהינם נותני השירות, והיא לא תהא אחראית כלפי המנוי או כל מאן דהוא לרבות המנויים, בגין מעשים ו/או מחדלים של הספק ו/או נותני השירותים בכל מקרה ובכל עניין לרבות בגין אי מתן שירותים נשוא כתב השירות.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> מבלי לגרוע מן האמור לעיל, בריאות ישיר והספק לא יהיו אחראים באופן כלשהו לגבי כל אחד מהעניינים הבאים:</Text>
                                <OrderedList>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> איכות הבדיקות, ההתייעצויות והפרוצדורות הרפואיות נשוא כתב השירות.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> רשלנות מקצועית או אחרת ו/או כל נזק לגוף או לרכוש או הפסד אובדן או תוצאה אחרת מכל מין ו/או סוג שהוא שייגרם למנוי ו/או לכל אדם אחר בקשר עם השירותים, אם בשל מעשה או בשל מחדל, אם נזק ישיר או נזק עקיף.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> הוצאות שהוציא מנוי עבור טיפול החורג מהשירותים המפורטים בכתב השירות.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> יובהר כי אין בהעברת מידע רפואי על המנוי לנותן השירות בכדי להוות קבלת המידע הרפואי בידי החברה.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> בריאות ישיר תהא רשאית, על פי שיקול דעתה הבלעדי, להגביל או להפסיק את אספקת אחד או יותר מהשירותים על פי כתב השירות למנוי מסוים ו/או לכלל המנויים וזאת מכל סיבה שהיא ומבלי שתחול עליה החובה לנמק החלטתה זו. במקרה של ביטול, שינוי או הגבלת שירותים תמסור בריאות ישיר הודעה בכתב למנויים הזכאים לשירות וזאת תוך 30 יום לפני ביצוע השינוי. במקרה של ביטול שירות יעדכנו דמי המנוי בהתאם (אם וככל שהדבר יידרש). ביטול שירות בין אם ללקוח מסוים ובין לכלל הלקוחות לא ישנה את יתר תנאי ההתקשרות.</Text>
                                    </ListItem>
                                </OrderedList>
                            </ListItem>
                        </OrderedList>
                    </ListItem>
                </OrderedList>

                <Text variant="runningText" >בכבוד רב,</Text>
                <Text variant="runningTextSb">בריאות ישיר שירותי רפואה בע"מ</Text>
                <Button variant={"greenButton"} onClick={handleConfirm} isLoading={isLoading}>אישור</Button>
            </VStack>
        </>
    )
}