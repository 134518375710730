import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({

    container: {
    },
    label: {
        color: "primary.runningText",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
        "&.chakra-checkbox__label":{
            fontSize: "16px",
        }
    },
    control: {
        borderWidth: "1px",
        borderRadius: "5px",
        borderColor: "primary.runningText",
        _checked:{
            bg: "primary.runningText",
            _hover: {
                bg: "primary.runningText",
            },
        },
    },
    icon: {
    },
});

const brand = defineStyle({});

const checkboxTheme = defineStyleConfig({
    baseStyle,
    variants: { brand },
    defaultProps: {
        variant: "brand",
    },
});
export default checkboxTheme;
