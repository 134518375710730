import { Button, Divider, Heading, ListItem, OrderedList, Stack, Table, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import api from "../services/api";
import { useState } from "react";

export default function MedicalDisclosure360({ client, urlData }) {

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const handleConfirm = async () => {
        setIsLoading(true);
        try {
            const resp = await api.approveClientProduct(urlData);
            if (resp.data.message.code === 1) {
                navigate("/success");
            }
        } catch (error) {
            console.error("An error occurred in approveClientProduct:", error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Heading as="h1" variant="h1">אישור הזמנת שירות</Heading>
            <VStack p={{ base: "60px 30px", md: "60px 130px" }} gap="30px">
                <Heading as="h2" variant="h2">טופס גילוי מדיקל 360
                    <Divider borderColor={"primary.green"} borderWidth="3px" w="50%" m="10px auto auto auto" />
                </Heading>
                <Text variant="runningText" >שלום {client?.name}</Text>
                <Text variant="runningTextSb">מסמך זה מהווה טופס גילוי בלבד אשר אינו כולל את כל התנאים של כתב השירות המלא. רק כתב השירות המלא כולל את כל תנאי ההתקשרות.</Text>
                <OrderedList textAlign={"left"} spacing={3} color={"primary.runningText"} fontWeight={"bold"}>
                    <ListItem>
                        <Text variant="runningTextSb" as="h3" mb="10px">פרטי הספק</Text>
                        <Text variant="runningText">בריאות ישיר שירותי רפואה בע"מ, ח.פ. 514906197 ("<b>בריאות ישיר</b>");</Text>
                        <Text variant="runningText">כתובת: חיים לבנון 63, תל אביב-יפו, מיקוד 6997804</Text>
                        <Text variant="runningText">פרטי התקשרות: 072-2248888; פקס: 03-5031872; דוא"ל: Sherut@byashir.co.il</Text>
                        <Text variant="runningText">כל השירותים יסופקו באמצעות "הרצליה מדיקל סנטר" ח.פ 513609867, או נותן שירות אחר שמונה על ידי החברה למתן השירותים ("<b>הספק</b>");</Text>
                    </ListItem>
                    <ListItem>
                        <Text variant="runningTextSb" as="h3" mb="10px">השירותים הכלולים במסגרת העסקה</Text>
                        <OrderedList marginInlineStart={"0em"}>
                            <ListItem>
                                <Text as="span" variant="runningText">שירות אבחון ראשוני - במסגרת התנאים והשירותים המפורטים בכתב השירות. בגין שירות זה ישלם המנוי דמי השתתפות עצמית בסך 250₪.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText">שירות אבחון מורחב - במסגרת התנאים והשירותים המפורטים בכתב השירות. בגין שירות זה ישלם המנוי השתתפות עצמית בסך 500₪.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText">שירות רופא ממומחה ובדיקות בתור מהיר טרום ניתוח - במסגרת התנאים והשירותים המפורטים בכתב השירות. בגין שירות זה ישלם המנוי דמי השתתפות עצמית בסך 250₪.</Text>
                                <OrderedList>
                                    <ListItem>
                                        <Text as="span" variant="runningText">במסגרת שירות זה המנוי זכאי לבדיקות אבחנתיות המפורטות להלן:</Text>
                                        <Stack ml="20px" my="10px" role="list">
                                            <Text variant="runningText" role="listitem">o בדיקת M.R.I.</Text>
                                            <Text variant="runningText" role="listitem">o בדיקת C.T.</Text>
                                            <Text variant="runningText" role="listitem">o בדיקת .C.T cardiac</Text>
                                            <Text variant="runningText" role="listitem">o קולונוסקופיה וירטואלית.</Text>
                                            <Text variant="runningText" role="listitem">o אולטרסאונד.</Text>
                                            <Text variant="runningText" role="listitem">o ממוגרפיה.</Text>
                                        </Stack>
                                    </ListItem>

                                    <ListItem>
                                        <Text as="span" variant="runningText">בנוסף לאמור בסעיף 2.3 לעיל, ישלם המנוי השתתפות עצמית למכון ההסדר או לספק, כפי שיימסר למנוי על ידי מוקד השירות, בסכומים המפורטים להלן:</Text>
                                        <Table w="auto">
                                            <Thead>
                                                <Tr>
                                                    <Th>סוג הבדיקה</Th>
                                                    <Th>סכום השתתפות עצמית</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                <Tr>
                                                    <Td>MRI</Td>
                                                    <Td>500 ₪</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>C.T cardiac	</Td>
                                                    <Td>500 ₪</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>קולונוסקופיה וירטואלית	</Td>
                                                    <Td>500 ₪</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>C.T</Td>
                                                    <Td>500 ₪</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>אולטרסאונד</Td>
                                                    <Td>500 ₪</Td>
                                                </Tr>
                                                <Tr>
                                                    <Td>ממוגרפיה</Td>
                                                    <Td>500 ₪</Td>
                                                </Tr>
                                            </Tbody>
                                        </Table>
                                    </ListItem>
                                </OrderedList>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText">מרכז מתקדם לדימות שד - במסגרת התנאים והשירותים המפורטים בכתב השירות.</Text>
                                <OrderedList>
                                    <ListItem>
                                        <Text as="span" variant="runningText">עבור כל בדיקה ישלם המנוי השתתפות עצמית של 250₪.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText">מובהר כי הבדיקות המתוארות בכתב השירות במסגרת שירות זה, יתאפשרו פעם אחת בלבד בכל תקופה בת שנתיים (24 חודשים).</Text>
                                    </ListItem>
                                </OrderedList>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText">שרות מלווה אישי במהלך ניתוח - במסגרת התנאים והשירותים המפורטים בכתב השירות.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText">אשפוז בחדר פרטי – על בסיס מקום פנוי והכל בכפוף ובמסגרת התנאים והשירותים המפורטים בכתב השירות.</Text>
                            </ListItem>
                        </OrderedList>
                    </ListItem>
                    <ListItem>
                        <Text variant="runningTextSb" as="h3" mb="10px">מחיר העסקה הכולל</Text>
                        <OrderedList marginInlineStart={"0em"}>
                            <ListItem>
                                <Text as="span" variant="runningText"> סכום דמי המנוי הינו {client?.total} ₪ לחודש.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> סכום דמי המנוי יהיו צמודים למדד יוקר הבריאות כהגדרתו ובהתאם לתנאים הנקובים בכתב השירות.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> בריאות ישיר שומרת לעצמה את הזכות לעדכן אחת לתקופה של 48 חודשים בשיעור של עד 5% בכל פעם, את הסכומים הנקובים בכתב השירות, לרבות את דמי המנוי וסכומי ההשתתפות העצמית, ובלבד שתימסר על כך הודעה למנוי 30 יום לפחות לפני מועד השינוי.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText">מובהר ומוסכם בזאת, כי בריאות ישיר רשאית לבטל זכאותו של המנוי לשירותים על פי כתב השירות בכל עת ומכול סיבה שהיא בכפוף להודעה מוקדמת למנוי בת 30 ימים מראש ובכתב. ככל שיבוטל מתן השירות כאמור על ידי בריאות ישיר, תבצע בריאות ישיר עדכון לתשלום המשולם על ידי המנוי בהתאמה.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> על אף האמור לעיל, במקרה של ביטול ו/או סיום ו/או פקיעת ההסכם שבין בריאות ישיר לבין הספק בקשר עם מתן השירותים נשוא כתב השירות, מכל סיבה שהיא, רשאית בריאות ישיר להפסיק מתן השירותים על פי כתב השירות, לאחר שהודיעה על כך בכתב למנויים ו/או תהיה רשאית בריאות ישיר להמשיך את מתן השירותים כמוגדר בכתב השרות באמצעות ספק אחר וזאת עפ"י שיקול דעתה הבלעדי של בריאות ישיר.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> מובהר, כי במועד פקיעתו, ביטולו או סיום תקפו של כתב השירות, מכל סיבה שהיא, תפקע כל זכות של המנוי לקבלת השירותים על פי כתב השירות, לרבות במקרה שהמנוי פנה למוקד השירות לקבלת השירות לפני ביטולו, פקיעתו או סיום תקפו של כתב השירות, אך טרם קיבל את השירות בפועל, ולרבות במקרה שהמנוי התחיל בקבלת שירות נשוא כתב השירות לפני סיום תקפו אך לא השלימו.</Text>
                            </ListItem>
                        </OrderedList>
                    </ListItem>
                    <ListItem>
                        <Text variant="runningTextSb" as="h3" mb="10px">זכות המנוי לביטול העסקה</Text>
                        <Text variant="runningText">המנוי רשאי לבטל את העסקה תוך 30 ימים מיום החתימה על החוזה או מיום אישור המנוי כי קיבל את טופס הגילוי, לפי המאוחר.</Text>
                    </ListItem>
                    <ListItem>
                        <Text variant="runningTextSb" as="h3" mb="10px">אחריות</Text>
                        <OrderedList marginInlineStart={"0em"}>
                            <ListItem>
                                <Text as="span" variant="runningText"> בריאות ישיר אינה אחראית לטיב השירותים הרפואיים ו/או האחרים הניתנים למנוי במסגרת כתב השירות. בריאות ישיר אינה אחראית לכל נזק, הפסד הוצאה או תוצאה אחרת שיגרמו למנוי ו/או לכל אחד אחר על ידי הספק ו/או מי מטעמו ו/או עקב בחירתו של המנוי ו/או הפנייתו לנותן השירות ו/או עקב מעשה או מחדל של הספק או נותן השירות או כל גורם הפועל מטעמו, לרבות בגין אי מתן השירות במועד שנקבע לכך מכל סיבה שהיא.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> מבלי לגרוע מן האמור לעיל, מובהר כי בריאות ישיר אינה המעסיקה של הספק ו/או של נותני השירות, מרפאות ומכונים שהינם נותני השירות, והיא לא תהא אחראית כלפי המנוי או כל מאן דהוא לרבות המנויים, בגין מעשים ו/או מחדלים של הספק ו/או נותני השירותים בכל מקרה ובכל עניין לרבות בגין אי מתן שירותים נשוא כתב השירות.</Text>
                            </ListItem>
                            <ListItem>
                                <Text as="span" variant="runningText"> מבלי לגרוע מן האמור לעיל, בריאות ישיר והספק לא יהיו אחראים באופן כלשהו לגבי כל אחד מהעניינים הבאים:</Text>
                                <OrderedList>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> איכות הבדיקות, ההתייעצויות והפרוצדורות הרפואיות נשוא כתב השירות.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> רשלנות מקצועית או אחרת ו/או כל נזק לגוף או לרכוש או הפסד אובדן או תוצאה אחרת מכל מין ו/או סוג שהוא שייגרם למנוי ו/או לכל אדם אחר בקשר עם השירותים, אם בשל מעשה או בשל מחדל, אם נזק ישיר או נזק עקיף.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> הוצאות שהוציא מנוי עבור טיפול החורג מהשירותים המפורטים בכתב השירות.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> יובהר כי אין בהעברת מידע רפואי על המנוי לנותן השירות בכדי להוות קבלת המידע הרפואי בידי החברה.</Text>
                                    </ListItem>
                                    <ListItem>
                                        <Text as="span" variant="runningText"> בריאות ישיר תהא רשאית, על פי שיקול דעתה הבלעדי, להגביל או להפסיק את אספקת אחד או יותר מהשירותים על פי כתב השירות למנוי מסוים ו/או לכלל המנויים וזאת מכל סיבה שהיא ומבלי שתחול עליה החובה לנמק החלטתה זו. במקרה של ביטול, שינוי או הגבלת שירותים תמסור בריאות ישיר הודעה בכתב למנויים הזכאים לשירות וזאת תוך 30 יום לפני ביצוע השינוי. במקרה של ביטול שירות יעדכנו דמי המנוי בהתאם (אם וככל שהדבר יידרש). ביטול שירות בין אם ללקוח מסוים ובין לכלל הלקוחות לא ישנה את יתר תנאי ההתקשרות.</Text>
                                    </ListItem>
                                </OrderedList>
                            </ListItem>
                        </OrderedList>
                    </ListItem>
                </OrderedList>

                <Text variant="runningText" >בכבוד רב,</Text>
                <Text variant="runningTextSb">בריאות ישיר שירותי רפואה בע"מ</Text>
                <Button variant={"greenButton"} onClick={handleConfirm} isLoading={isLoading}>אישור</Button>
            </VStack>
        </>
    )
}