import './App.css';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import InspectionDetails from './pages/InspectionDetails';
import AppointmentDetails from './pages/AppointmentDetails';
import MedicalDisclosure from './pages/MedicalDisclosure';
import Success from './pages/Success';
import { Box, Button, Container, HStack, Image, Link, Text, VStack } from '@chakra-ui/react';
import Header from './style/layout/Header';
import Footer from './style/layout/Footer';
import MedicalDisclosureWrapper from './pages/MedicalDisclosureWrapper';
import TreatmentCard from './pages/TreatmentCard';
import { useEffect } from 'react';

export default function App() {

  const url = useLocation().pathname;

  function AppRoutes() {
    return (
      <Routes>
        <Route path="/appointmentDetails" element={<AppointmentDetails />} />
        <Route path="/inspectionDetails" element={<InspectionDetails />} />
        <Route path="/treatmentCard" element={<TreatmentCard />} />
        <Route path="/medicalDisclosure" element={<MedicalDisclosureWrapper />} />
        <Route path="/success" element={<Success />} />
      </Routes>
    );
  }

  useEffect(() => {
    const pagesUsingH2 = "/medicalDisclosure";
    let pageTitle;
    if (pagesUsingH2.includes(url)) {
      pageTitle = document.querySelector('h2')?.textContent || "בריאות ישיר"; //take title from h2
    }
    else {
      pageTitle = document.querySelector('h1')?.textContent || "בריאות ישיר"; //take title from h1
    }
    document.title = pageTitle;
  }, []);

  return (
    <>
      {url === "/success" ?

        <Container as="main" variant={"main"}>
          <AppRoutes />
        </Container>
        :
        <>
          <Header />
          <Container as="main" variant={"main"}>
            <Box
              backgroundImage='linear-gradient(rgba(56, 64, 70, 0.3), rgba(56, 64, 70, 0.3)), url("/assets/images/medical4youPlazma.jpg")'
              backgroundSize='cover'
              width='100%'
              height='713px'
            />
            <AppRoutes />
          </Container>
          <Footer />

        </>

      }

    </>


  );
}
