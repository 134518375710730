import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const greenButton = defineStyle({
  p: "10px 50px",
  bgColor: "primary.lightGreen",
  color: "white",
  fontSize: "22px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  _hover: {
    _disabled: {
      bgColor: "primary.lightGreen",
    },
  },
  _disabled: {
  },
});

const scrollTop = defineStyle({
  borderRadius: "50%",
  width: "95px",
  height: "95px",
  boxShadow: "1.41px 1.41px 13px 2px rgba(0, 0, 0, 0.22)",
  background: "rgba(129, 185, 187, 0.73)",
  position: "fixed",
  bottom: "130px",
  left: "25px",
});

const buttonTheme = defineStyleConfig({
  variants: { scrollTop,greenButton },
});

export default buttonTheme;