import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MedicalDisclosure from './MedicalDisclosure';
import MedicalDisclosure360 from './MedicalDisclosure360';
import MedicalDisclosure360plus from './MedicalDisclosure360plus';
import axios from 'axios';
import api from '../services/api';

export default function MedicalDisclosureWrapper() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const p = queryParams.get('p');
    const t = queryParams.get('t');
    const [clientData, setClientData] = useState(null);
    const urlData = { taskId: t, productId: p }

    useEffect(() => {
         api.getClientData(urlData)
            .then(response => {
                setClientData(response.data); 
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
            });
    }, [t]);

    switch (p) {
        case '9':
            return <MedicalDisclosure client={clientData} urlData={urlData} />;
        case '5':
            return <MedicalDisclosure360 client={clientData} urlData={urlData} />;
        case '14':
            return <MedicalDisclosure360plus client={clientData} urlData={urlData} />;
        default:
            return <div>אירעה שגיאה</div>;
    }
};
