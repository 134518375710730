export default function mergeDateWithTime(dateStr, timeStr) {

    const date = new Date(dateStr);
    const [hours, minutes] = timeStr.split(":");
    date.setHours(parseInt(hours, 10) + 3);
    date.setMinutes(parseInt(minutes, 10));
    const formattedDate = date.toISOString().substring(0, 19);

    return formattedDate;
}
