import { tableAnatomy as parts } from "@chakra-ui/anatomy";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";

const { defineMultiStyleConfig, definePartsStyle } =
  createMultiStyleConfigHelpers(parts.keys);

const brand = definePartsStyle((props) => {
  return {
    table: {
     m: "10px"
    },
    th: {
      fontFamily: "heebo-light, sans-serif",
      borderBottom: "none",
      fontSize: ["14px", "18px"], // Mobile: 16px, Desktop: 18px
      fontStyle: "normal",
      fontWeight: 600,
      padding: "10px",
    },
    td: {
      borderBottom: "none",
      fontSize: ["14px", "18px"], // Mobile: 16px, Desktop: 18px
      fontStyle: "normal",
      fontWeight: 400,
      padding: "10px",
    },
    tr: {
      ":not(:last-child)": {
        td: {},
      },
    },
    caption: {},
    tfoot: {
      tr: {
        "&:last-of-type": {
          th: {},
        },
      },
    },
  };
});

const variants = {
  brand,
  unstyled: defineStyle({}),
};

const tableTheme = defineMultiStyleConfig({
  variants,
  defaultProps: {
    variant: "brand",
  },
});
export default tableTheme;
