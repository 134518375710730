import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const baseStyle = defineStyle({
    // maxW: "490px",
    w: "100%",
    p: "16px 11px",
    boxShadow: " 0px 2px 20px 0px rgba(0, 0, 0, 0.08)",
    borderRadius: "12px",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    color: "primary.runningText",
    borderWidth: "1px",
    borderColor: "primary.runningText",
  });

const brand = defineStyle({});

const textareaTheme = defineStyleConfig({
    baseStyle,
    variants: { brand },
    defaultProps: {
        variant: "brand",
    },
});

export default textareaTheme;
