import { Alert, AlertIcon, Button, Checkbox, FormControl, FormErrorMessage, FormLabel, HStack, Heading, Select, Text, Textarea, VStack } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import he from "date-fns/locale/he";
import moment from "moment";
import "../style/css/datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../services/api";
import { useEffect, useState } from "react";
import { formatDate, formatTime } from "../reusableCode/formatDateAndTime";
import generateTimeOptions from "../reusableCode/generateTimeOptions";
import mergeDateWithTime from './../reusableCode/mergeDateWithTime';

export default function InspectionDetails() {


    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uq = queryParams.get('uq');

    const [appointment, setAppointment] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm({ mode: "onChange" });

    const onSubmit = async (formData) => {
        setIsLoading(true);
        console.log("formData: ", formData);

        // Update the appointment object with form data
        const updatedAppointment = { ...appointment };
        updatedAppointment.appointmentDataRow = updatedAppointment.appointmentDataRow.map((ap, index) => ({
            ...ap,
            selected: true,
            ...(!ap.approved && { //update this field just if not approved
                appointemtDate: mergeDateWithTime(formData.appointmentDataRow[index].appointemtDate, formData.appointmentDataRow[index].appointemtTime)
            }),
            sDescription: formData.appointmentDataRow[index].sDescription,
        }));
        console.log(updatedAppointment)
        try {
            const resp = await api.approveApprointments(updatedAppointment);
            if (resp.data.message.code === 1) {
                navigate("/success");
            }

        } catch (error) {
            console.error("An error occurred in :", error);

        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        api.getAppointmentDataForSupplier({ uniqId: uq })
            .then(response => {
                console.log(response.data)
                setAppointment(response.data);
            })
            .catch(error => {
                console.error('Error fetching user data:', error);
            });
    }, []);

    return (
        <>
            <Heading as="h1" variant="h1">אישור הזמנת שירות</Heading>
            <VStack p="60px 16px">
                {appointment?.cName && <HStack>
                    <Text variant="runningTextSb">שם המנוי\ה: </Text>
                    <Text variant="runningText">{appointment.cName}</Text>
                </HStack>
                }
                {appointment?.cIdNumber && <HStack>
                    <Text variant="runningTextSb">מס ת.ז: </Text>
                    <Text variant="runningText">{appointment.cIdNumber}</Text>
                </HStack>
                }
                {appointment?.cPhone && <HStack>
                    <Text variant="runningTextSb">טלפון הנבדק/ת ו/או איש קשר: </Text>
                    <Text variant="runningText">{appointment.cPhone}</Text>
                </HStack>
                }
                {appointment?.productName && <HStack>
                    <Text variant="runningTextSb">שם כתב השירות: </Text>
                    <Text variant="runningText">{appointment.productName}</Text>
                </HStack>
                }
                {appointment?.treatmentName && <HStack>
                    <Text variant="runningTextSb">עבור: </Text>
                    <Text variant="runningText">{appointment.treatmentName}</Text>
                </HStack>
                }
                {appointment?.creationDate && <HStack>
                    <Text variant="runningTextSb">תאריך הפקת הטופס: </Text>
                    <Text variant="runningText">{formatDate(appointment.creationDate)}</Text>
                </HStack>
                }

                <VStack as="form" onSubmit={handleSubmit(onSubmit)} maxW="580px" alignItems={"flex-start"} textAlign={"left"} gap="24px" pt="30px">

                    {appointment?.appointmentDataRow.map((ap, index) => {
                        return <VStack gap="24px" w="100%" alignItems={"flex-start"} key={ap.appointmentId}>

                            <Text variant="runningTextSb">תואם ל: </Text>

                            {ap.approved ? <>
                                <Text variant="runningText">{`תאריך: ${formatDate(ap.appointemtDate)} בשעה: ${formatTime(ap.appointemtDate)}`}</Text>
                                <Alert status='warning' borderRadius={"12px"}>
                                    <AlertIcon />
                                    טיפול בוצע - דיווח עודכן.
                                    אין באפשרותך לערוך את הפרטים, אך ניתן להוסיף הערה
                                </Alert>
                            </>
                                :
                                <HStack maxW="580px" w="100%" justifyContent={"space-between"}>
                                    <FormControl isInvalid={errors.appointemtDate} maxW="230px" w="100%">
                                        <FormLabel>תאריך *</FormLabel>
                                        <div className="flex-container">
                                            <Controller
                                                name={`appointmentDataRow[${index}].appointemtDate`}
                                                control={control}
                                                //defaultValue={moment(ap.appointemtDate).toDate()}
                                                rules={{ required: "שדה חובה" }}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        calendarClassName="brand-cal"
                                                        {...field}
                                                        selected={field.value}
                                                        onChange={(date) => field.onChange(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        // placeholderText="dd/mm/yyyy"
                                                        // disabled={ap.approved}
                                                        locale={he}
                                                    />
                                                )}
                                            />
                                        </div>
                                        {errors.appointemtDate && (
                                            <FormErrorMessage color="primary.error">
                                                {errors.appointemtDate.message}
                                            </FormErrorMessage>
                                        )}
                                    </FormControl>

                                    <FormControl isInvalid={errors.appointemtTime} maxW="230px" w="100%">
                                        <FormLabel>שעה *</FormLabel>
                                        <Select
                                            name={`appointmentDataRow[${index}].appointemtTime`}
                                            placeholder='--:--'
                                            //value={formatTime(ap.appointemtDate)}
                                            //disabled={ap.approved}
                                            {...register(`appointmentDataRow[${index}].appointemtTime`, { required: "שדה חובה" })}
                                        >
                                            {
                                                generateTimeOptions().map((time, index) => {
                                                    return <option key={index} value={time}>{time}</option>
                                                })
                                            }
                                        </Select>
                                        {errors.appointemtTime && (
                                            <FormErrorMessage color="primary.error">
                                                {errors.appointemtTime.message}
                                            </FormErrorMessage>
                                        )}
                                    </FormControl>
                                </HStack>
                            }

                            <FormControl isInvalid={errors.sDescription}>
                                <FormLabel>הערה</FormLabel>
                                <Textarea
                                    name={`appointmentDataRow[${index}].sDescription`}
                                    defaultValue={ap.sDescription}
                                    placeholder="כתוב הערה..."
                                    {...register(`appointmentDataRow[${index}].sDescription`)}
                                />
                                {errors.sDescription && (
                                    <FormErrorMessage color="primary.error">
                                        {errors.sDescription.message}
                                    </FormErrorMessage>
                                )}

                            </FormControl>
                        </VStack>
                    })}

                    <Text variant="runningText">השתתפות עצמית של המנוי/ה שולמה ישירות לבריאות ישיר שירותי רפואה בע''מ</Text>
                    <Text variant="runningText">התחייבות זו בתוקף עבור בדיקה אחת בלבד</Text>
                    <FormControl isInvalid={errors.confirm}>
                        <Checkbox
                            name="confirm"
                            mt="16px"
                            {...register("confirm", { required: "שדה חובה" })}
                        >הריני מאשר/ת את ההצהרות לעיל *
                        </Checkbox>
                        {errors.confirm && (
                            <FormErrorMessage color="primary.error">
                                {errors.confirm.message}
                            </FormErrorMessage>
                        )}
                    </FormControl>
                    <Button type="submit" variant={"greenButton"} mt="16px" isDisabled={!isDirty || !isValid} isLoading={isLoading}>אישור</Button>
                </VStack>
            </VStack>
        </>
    )
}